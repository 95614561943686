import { useState, useEffect, useCallback, useReducer } from "react";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { Container, Row, Col, Spinner } from "reactstrap";

import Pagination from "@views/VehicleList/components/Pagination";
import styles from "./index.module.scss";
import TableStock from "@views/VehicleList/components/TableStock";
import { dispatcher } from "@redux/actions/dispatchers";
import { getDrafts } from "@services/Vehicle/vehicle.service";
import { useTranslation } from "react-i18next";

import { getActiveLocals } from "@services/Employees/getActiveLocals";

import SearchFilterExtended from "@components/SearchFilterExtended";
import Paginator from "@components/Paginator";
import {
	VEHICLE_VALIDATION_ACTIONS,
	vehicleValidationFiltersInitialState,
	vehicleValidationFiltersReducer
} from "@views/VehicleList/actions/vehicleValidationReducer";
import { useDebouncedValue } from "@hooks/useDebouncedValue";
import { errorToast } from "@helpers/toastFunctions";

const Stock = ({
	vehicle_search: storedVehicleSearch,
	vehicleSearch: dispatchSetVehicleSearch
}) => {
	const { t } = useTranslation("vehicleValidationList");
	const history = useHistory();

	const [cars, setCars] = useState({ results: null, count: 0 });
	const [localSelectOptions, setLocalSelectOptions] = useState([]);

	const [filterState, filterDispatch] = useReducer(
		vehicleValidationFiltersReducer,
		storedVehicleSearch || vehicleValidationFiltersInitialState
	);
	const plateFilterDebounce = useDebouncedValue(filterState.plate, 500);

	const searchCars = useCallback(async () => {
		if (!filterState.isUpdating) {
			const dataCall = {
				page: filterState.page,
				size: filterState.pageSize,
				locals: filterState.locales,
				order: "date",
				orderDir: "DESC",
				buyAndSell: filterState.buyAndSell
			};

			if (plateFilterDebounce === "" || plateFilterDebounce.length >= 6) {
				if (plateFilterDebounce !== "") dataCall.plate = plateFilterDebounce;

				try {
					const response = await getDrafts(dataCall);
					const { results, total } = response;
					setCars({ results, count: total });
				} catch (e) {
					errorToast();
				}
			}
		} else if (filterState.isUpdating && plateFilterDebounce === "") {
			filterDispatch({ type: VEHICLE_VALIDATION_ACTIONS.COMPLETE_UPDATE });
		}
	}, [
		filterState.isUpdating,
		filterState.page,
		filterState.pageSize,
		plateFilterDebounce,
		filterState.locales,
		filterState.buyAndSell
	]);

	const handleChange = (event) =>
		event.target.type === "checkbox"
			? filterDispatch({
					type: VEHICLE_VALIDATION_ACTIONS.SET_BUY_AND_SELL,
					payload: event.target.checked
			  })
			: filterDispatch({ type: VEHICLE_VALIDATION_ACTIONS.SET_PLATE, payload: event.target.value });

	const loadVehiclePage = (id) => {
		dispatchSetVehicleSearch(filterState);
		history.push(`/main/validacion-vehiculo/${id}?tab=datos`);
	};

	useEffect(() => {
		(async () => {
			const responseLocalSelectOptions = await getActiveLocals();
			setLocalSelectOptions(
				responseLocalSelectOptions.map((x) => ({
					value: x.id,
					label: x.name
				}))
			);
		})();
	}, []);

	useEffect(() => {
		document.title = "Flexicar | Validación de vehiculo";
		searchCars();
	}, [searchCars]);

	return (
		<div className="App">
			<Container
				className="themed-container mt-4"
				fluid={true}
			>
				<Row className="m-0">
					<Col className="col-12 col-md-12">
						<div className={`clearfix mt-3 mb-3 ${styles.container2}`}>
							<span className="tittle ml-4">{t("vehiclesValidation")}</span>
						</div>
					</Col>
					<Col className="col-12">
						<Row className="mb-1 float-md-right">
							<div className="col-12 col-md-8 p-0 ml-md-0 ml-3">
								<SearchFilterExtended
									searchLabel={t("plate")}
									searchValue={filterState.plate}
									searchPlaceholder={t("search")}
									handleChange={handleChange}
									dropdownLabel={t("local")}
									dropdownPlaceholder={t("all")}
									dropdownSelectOptions={localSelectOptions}
									dropdownSelected={filterState.locales}
									setDropdownSelected={(locales) =>
										filterDispatch({
											type: VEHICLE_VALIDATION_ACTIONS.SET_LOCALES,
											payload: locales
										})
									}
									checkboxLabel={t("professionalsBuySale")}
									checkboxValue={filterState.buyAndSell}
								/>
							</div>
							<Col
								md="2"
								xs="6"
								className="text-right mt-4"
							>
								<Pagination
									changePagination={(_, newSize) =>
										filterDispatch({
											type: VEHICLE_VALIDATION_ACTIONS.SET_PAGE_SIZE,
											payload: newSize
										})
									}
									showNumb={filterState.pageSize}
								/>
							</Col>
							<Col
								md="2"
								xs="6"
								className="text-right mt-4"
							>
								<span className="text-success">{t("vehiclesValidation")}: </span>
								<span className="text-primary">{cars.count}</span>
							</Col>
						</Row>

						{cars.results?.length > 0 ? (
							<>
								<TableStock
									cars={cars.results}
									loadVehiclePage={loadVehiclePage}
									filterDispatch={filterDispatch}
								/>

								<div className="flex justify-content-center pt-2 pb-4">
									<Paginator
										pageSize={filterState.pageSize}
										totalCount={cars.count}
										currentPage={filterState.page}
										onPageChange={(page) =>
											filterDispatch({ type: VEHICLE_VALIDATION_ACTIONS.SET_PAGE, payload: page })
										}
									/>
								</div>
							</>
						) : (
							<div className="d-flex justify-content-center pt-5 w-100">
								{cars.results === null && (
									<>
										<Spinner
											type="grow"
											className="flexicarColor"
										/>
										<p className="flexicarColor"> {t("loading")}</p>
									</>
								)}

								{cars.results?.length === 0 && (
									<p className="flexicarColor">No se encontraron resultados</p>
								)}
							</div>
						)}
					</Col>
				</Row>
			</Container>
		</div>
	);
};

const mapStateToProps = (store) => ({
	vehicle_search: store.auth.vehicle_search
});

const mapDispatch = dispatcher(["vehicleSearch"]);

export default connect(mapStateToProps, mapDispatch)(withRouter(Stock));
