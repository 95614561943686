import { useEffect, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { MdInfoOutline } from "react-icons/md";
import { UncontrolledTooltip } from "reactstrap";

import styles from "@styles/views/SellContract/sell-data.module.scss";

import PaneHeader from "@components/PaneHeader";
import FormInput from "@components/Form/Input/FormInput";
import FormSelect from "@components/Form/Select/FormSelect";
import FormFormattedNumberInput from "@components/Form/FormattedNumberInput/FormFormattedNumberInput";
import FormCheckBox from "@components/Form/Checkbox/FormCheckbox";
import FormRadioButtonGroup from "@components/Form/RadioButtonGroup/FormRadioButtonGroup";
import FormIBAN from "@components/Form/IBAN/FormIBAN";
import {
	insouranceOptions,
	termOptions,
	transportDeliveryTypeOptions,
	transportSellTypeOptions,
	yesOrNotSelect
} from "@constants/selectOptions";
import BankOptions from "@constants/bankOptions";
import { SELL_CONTRACT_PRICE_TYPES } from "@constants/sellContratDefaults";
import instance from "@services/base";
import { API } from "@services/urlConstants";
import { errorToast } from "@helpers/toastFunctions";

const SellData = ({
	gestoriasOptions,
	dealershipsOptions,
	provincesOptions,
	isDisabledForExtraWarranty,
	prices
}) => {
	const { watch, setValue } = useFormContext();

	const priceType = watch("sellInfo.priceType");

	const wantsFinancing = watch("sellInfo.financing.wants");

	const wantsTransport = watch("sellInfo.transport.wants");
	const deliveryType = watch("sellInfo.transport.delivery.type");
	const dealershipDelivery = watch("sellInfo.transport.delivery.dealership");
	const useSameAddress = watch("sellInfo.transport.delivery.useSameAddress");

	const warrantyPremium = watch("sellInfo.warranty.premium.wants");
	const extraWarranty = watch("sellInfo.warranty.extra.wants");

	const wantsInsurance = watch("sellInfo.insurance.wants");

	const priceTypeOptions = prices.buyAndSell
		? [
				{
					label: "PVD",
					value: SELL_CONTRACT_PRICE_TYPES.PVD
				}
		  ]
		: [
				{
					label: "PVP Contado",
					value: SELL_CONTRACT_PRICE_TYPES.PVP_CONTADO
				},
				{
					label: "PVP Financiado",
					value: SELL_CONTRACT_PRICE_TYPES.PVP_FINANCIADO
				}
		  ];

	const getProvinceByLocalId = useCallback(
		async (id) => {
			try {
				const response = await instance.post(API.GET_PROVINCIA_LOCAL, {
					id_local: id
				});
				setValue("sellInfo.transport.delivery.provinceDealership", response.data[0]);
			} catch (err) {
				errorToast(err?.response?.data?.message || "Error al obtener la provincia del local");
			}
		},
		[setValue]
	);

	useEffect(() => {
		dealershipDelivery?.value && getProvinceByLocalId(dealershipDelivery.value);
	}, [dealershipDelivery, getProvinceByLocalId]);

	useEffect(() => {
		const pvp = {
			[SELL_CONTRACT_PRICE_TYPES.PVP_CONTADO]: prices.priceCash,
			[SELL_CONTRACT_PRICE_TYPES.PVP_FINANCIADO]: prices.priceOffer || prices.priceFinanced,
			[SELL_CONTRACT_PRICE_TYPES.PVD]: prices.priceDealerSelling
		}[priceType];

		setValue("sellInfo.pvp", pvp);
	}, [setValue, priceType, prices]);

	return (
		<div>
			<PaneHeader title="Datos de la venta" />
			<div className={styles.container}>
				<div className={styles.priceOptions}>
					<FormRadioButtonGroup
						name="sellInfo.priceType"
						options={priceTypeOptions}
					/>
				</div>
				<div className={styles.module}>
					<FormFormattedNumberInput
						name="sellInfo.pvp"
						label="PVP Venta"
						disabled
					/>
					<FormFormattedNumberInput
						name="sellInfo.ownershipChangeAmount"
						label="Importe Cambio de titularidad"
					/>
					<FormSelect
						name="sellInfo.gestoria"
						label="Gestoría"
						options={gestoriasOptions}
					/>
				</div>

				<PaneHeader
					title="Financiación"
					color="secondary"
				/>
				<div className={styles.module}>
					<FormSelect
						name="sellInfo.financing.wants"
						label="Financia"
						options={yesOrNotSelect}
					/>

					{wantsFinancing?.value === yesOrNotSelect[0].value && (
						<>
							<FormFormattedNumberInput
								name="sellInfo.financing.discount"
								label="Descuento financiación"
							/>
							<FormSelect
								name="sellInfo.financing.financialEntity"
								label="Entidad financiera"
								options={BankOptions}
							/>

							<FormFormattedNumberInput
								name="sellInfo.financing.TIN"
								label="TIN"
								suffix=" "
								placeholder=" "
							/>
							<FormSelect
								name="sellInfo.financing.term"
								label="Plazo"
								options={termOptions}
							/>
							<FormFormattedNumberInput
								name="sellInfo.financing.quota"
								label="Cuota"
								suffix=" €/mes"
								placeholder=" "
							/>
						</>
					)}
				</div>

				<PaneHeader
					title="Transporte"
					color="secondary"
				/>
				<div className={styles.module}>
					<FormSelect
						name="sellInfo.transport.sellType"
						label="Tipo de venta"
						options={transportSellTypeOptions}
					/>

					<FormSelect
						name="sellInfo.transport.wants"
						label="Transporte"
						options={yesOrNotSelect}
					/>

					{/* If transport.wants es Si */}
					{wantsTransport?.value === yesOrNotSelect[0].value && (
						<>
							<FormFormattedNumberInput
								name="sellInfo.transport.delivery.amount"
								label="Importe de entrega"
							/>
						</>
					)}
				</div>

				{wantsTransport?.value === yesOrNotSelect[0].value && (
					<div className={styles.module}>
						<FormSelect
							name="sellInfo.transport.delivery.type"
							label="Forma de entrega"
							options={transportDeliveryTypeOptions}
						/>

						{/* If transport.delivery.type === Tienda */}
						{deliveryType?.value === transportDeliveryTypeOptions[0].value && (
							<FormSelect
								name="sellInfo.transport.delivery.dealership"
								label="Tienda de entrega"
								options={dealershipsOptions}
							/>
						)}
						{/* If transport.delivery.type === Tienda */}
						{deliveryType?.value === transportDeliveryTypeOptions[0].value && (
							<FormSelect
								name="sellInfo.transport.delivery.provinceDealership"
								label="Provincia Entrega"
								options={provincesOptions}
								isDisabled
							/>
						)}
						{/* If transport.delivery.type === Domicilio */}
						{deliveryType?.value === transportDeliveryTypeOptions[1].value && (
							<FormCheckBox
								name="sellInfo.transport.delivery.useSameAddress"
								label="Utilizar dirección de facturación"
							/>
						)}
					</div>
				)}

				{/* If transport.delivery.type === Domicilio && If transport.delivery.useSameAddress === false */}
				{deliveryType?.value === transportDeliveryTypeOptions[1].value && !useSameAddress && (
					<div className={styles.module}>
						<div className={styles.alternativeAddress}>
							<FormSelect
								name="sellInfo.transport.delivery.alternativeAddress.province"
								label="Provincia"
								options={provincesOptions}
							/>
							<FormInput
								name="sellInfo.transport.delivery.alternativeAddress.town"
								label="Población"
							/>
							<FormInput
								name="sellInfo.transport.delivery.alternativeAddress.address"
								label="Dirección"
							/>
							<FormInput
								name="sellInfo.transport.delivery.alternativeAddress.CP"
								label="CP"
							/>
						</div>
					</div>
				)}

				{!prices.buyAndSell && (
					<>
						<PaneHeader
							title="Garantía"
							color="secondary"
						/>
						<div className={styles.module}>
							<FormSelect
								name="sellInfo.warranty.premium.wants"
								label="Garantía Premium"
								options={yesOrNotSelect}
							/>

							{/* If sellInfo.warranty.premium.wants es Si */}
							{warrantyPremium?.value === yesOrNotSelect[0].value && (
								<FormFormattedNumberInput
									name="sellInfo.warranty.premium.amount"
									label="Importe garantía Premium"
								/>
							)}
						</div>

						{warrantyPremium?.value === yesOrNotSelect[0].value && (
							<div className={styles.module}>
								<>
									<FormSelect
										name="sellInfo.warranty.extra.wants"
										label={
											<div className={styles.extraWarrantyTooltip}>
												Extra Garantía
												<MdInfoOutline
													id="garantia-tooltip"
													className={styles.extraWarrantyTooltipIcon}
													size={22}
												/>
												<UncontrolledTooltip
													target="garantia-tooltip"
													style={{ opacity: 0.8 }}
												>
													La Garantía Extendida amplía la cobertura de la Garantía Premium durante
													un año adicional a través de Caser. No disponible para vehículos a partir
													de 8 años o 140.000km.
												</UncontrolledTooltip>
											</div>
										}
										options={yesOrNotSelect}
										isDisabled={isDisabledForExtraWarranty}
									/>

									{/* If sellInfo.warranty.premium.wants es Si && sellInfo.warranty.extra.wants es Si*/}
									{extraWarranty?.value === yesOrNotSelect[0].value && (
										<>
											<FormFormattedNumberInput
												name="sellInfo.warranty.extra.amount"
												label="Importe Extra Garantía"
												disabled
											/>
											<FormIBAN
												name="sellInfo.warranty.extra.IBAN"
												label="Nº de cuenta"
											/>
										</>
									)}
								</>
							</div>
						)}
					</>
				)}

				<PaneHeader
					title="Seguro del vehículo"
					color="secondary"
				/>
				<div className={styles.module}>
					<FormSelect
						name="sellInfo.insurance.wants"
						label="Seguro Auto"
						options={yesOrNotSelect}
					/>

					{/* If sellInfo.insurance.wants es Si */}
					{wantsInsurance?.value === yesOrNotSelect[0].value && (
						<>
							<FormSelect
								name="sellInfo.insurance.company"
								label="Compañía"
								options={insouranceOptions}
							/>
							<FormSelect
								name="sellInfo.insurance.financing"
								label="Financia"
								options={yesOrNotSelect}
							/>
							<FormFormattedNumberInput
								name="sellInfo.insurance.amount"
								label="Importe Seguro Auto"
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default SellData;
