import LEAD_STATUS from "@constants/LeadStatus";


export const SELL_FILTER_STATE = Object.freeze({
	APPOINTED: "citados",
	RESERVED: "reservados",
	ABSENCE: "no asiste",
	ARCHIVED: "archivados",
	MANAGING: "en gestión",
	WEB_APPOINTMENT: "cita web"
});



export const sellFiltersConfigES = [
	{
		filteri18n: "appointeds",
		filter: SELL_FILTER_STATE.APPOINTED,
		color: "#f0a23c",
	},
	{
		filteri18n: "reserved",
		filter: SELL_FILTER_STATE.RESERVED,
		color: "#ff6252",
	},
	{
		filteri18n: "absence",
		filter: SELL_FILTER_STATE.ABSENCE,
		color: "#82a2dd",
	},
	{
		filteri18n: "archived",
		filter: SELL_FILTER_STATE.ARCHIVED,
		color: "#2ecaac",
	},
	{
		filteri18n: "managing",
		filter: SELL_FILTER_STATE.MANAGING,
		color: "#bfbfbf",
	},
	{
		filteri18n: "web_appointment",
		filter: SELL_FILTER_STATE.WEB_APPOINTMENT,
		color: "#373737",
	}
];

export const sellFiltersConfigPT = [
	{
		filteri18n: "appointeds",
		filter: SELL_FILTER_STATE.APPOINTED,
		color: "#f0a23c",
	},
	{
		filteri18n: "reserved",
		filter: SELL_FILTER_STATE.RESERVED,
		color: "#ff6252",
	},
	{
		filteri18n: "absence",
		filter: SELL_FILTER_STATE.ABSENCE,
		color: "#82a2dd",
	},
	{
		filteri18n: "archived",
		filter: SELL_FILTER_STATE.ARCHIVED,
		color: "#2ecaac",
	},
	{
		filteri18n: "managing",
		filter: SELL_FILTER_STATE.MANAGING,
		color: "#bfbfbf",
	}
];

export const SELL_LEAD_STATES = Object.freeze({
	appointed: [LEAD_STATUS.citado],
	absence: [LEAD_STATUS.poca_tasacion, LEAD_STATUS.no_interesa, LEAD_STATUS.no_asiste_cita],
	reserved: [LEAD_STATUS.reservado_pago_transferencia, LEAD_STATUS.reservado_pendiente_pago_pasarela, LEAD_STATUS.reservado_pago_tienda, LEAD_STATUS.pagado_tarjeta_pasarela, LEAD_STATUS.pagado_tienda, LEAD_STATUS.pagado_bizum_pasarela, LEAD_STATUS.reserva_online],
	archived: [LEAD_STATUS.archivado, LEAD_STATUS.dudoso],
	webAppointment: [LEAD_STATUS.cita_web]
});

export const getSellColorByLeadState = (leadState) => {
	if (SELL_LEAD_STATES.appointed.includes(leadState)) return "#f0a23c";
	else if (SELL_LEAD_STATES.reserved.includes(leadState)) return "#ff6252";
	else if (SELL_LEAD_STATES.absence.includes(leadState)) return "#82a2dd";
	else if (SELL_LEAD_STATES.archived.includes(leadState)) return "#2ecaac";
	else if (SELL_LEAD_STATES.webAppointment.includes(leadState)) return "#373737";
	return "#bfbfbf";
};

export const getSellTypeNameByLeadState = (event) => {
	const leadState = event.lead.state;

	if (SELL_LEAD_STATES.appointed.includes(leadState)) return SELL_FILTER_STATE.APPOINTED;
	else if (SELL_LEAD_STATES.reserved.includes(leadState)) return SELL_FILTER_STATE.RESERVED;
	else if (SELL_LEAD_STATES.absence.includes(leadState)) return SELL_FILTER_STATE.ABSENCE;
	else if (SELL_LEAD_STATES.archived.includes(leadState)) return SELL_FILTER_STATE.ARCHIVED;
	else if (SELL_LEAD_STATES.webAppointment.includes(leadState)) return SELL_FILTER_STATE.WEB_APPOINTMENT;
	return SELL_FILTER_STATE.MANAGING;
};
