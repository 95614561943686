import moment from "moment";

import { post, get } from "@services/axiosCalls";
import { getFinance } from "@services/Finance/finance.service";
import { GetTextTranslate } from "@services/GoogleApis/googleApis.service";

const ejsTemplateRender = async (templateId, dataTemplate, divId = "template") => {

    const id_contrato = dataTemplate.contractId;
    const getResponse = async () => {
        switch (templateId) {
            case 1:
            case 2:
            case 3:
            case 4:
                return await post("/api/template", { template: templateId });
            case 5:
                if (!dataTemplate.factura_id) {
                    return await post("/api/ejs", { id_contrato });
                } else {
                    return await get("/api/ejsFactura");
                }
            case 0:
                return await post("/api/ejs", { id_contrato })
            default:
                break;
        }
    };
    const response = await getResponse();
    const html = response[0]?.temp;
    const ejs = require("ejs");

    const getData = async () => {
        switch (templateId) {
            case 1:
                const id_tasacion = dataTemplate.id_tasacion;
                const data = await post("/api/template_data", { id_tasacion });
                let data_template = data[0];
                const estados = await post("/api/get_estado_vehiculo", {
                    lead_id: id_tasacion,
                });

                return {
                    data: {
                        ...data_template,
                        marca: data_template.marca_name ?? data_template.marca,
                        modelo: data_template.modelo_name ?? data_template.modelo,
                        estados: estados,
                        extras: JSON.stringify(data_template.extras || [])
                    }
                };
            case 2: {
                const translationTemplate = {
                    yearTemplate: dataTemplate.t("yearTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    brandTemplate: dataTemplate.t("brandTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    modelTemplate: dataTemplate.t("modelTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    combustibleTemplate: dataTemplate.t("combustibleTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    transmissionTemplate: dataTemplate.t("transmissionTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    versionTemplate: dataTemplate.t("versionTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    kmTemplate: dataTemplate.t("kmTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    ecoStickerTemplate: dataTemplate.t("ecoStickerTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    seatsTemplate: dataTemplate.t("seatsTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    doorsTemplate: dataTemplate.t("doorsTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    colorTemplate: dataTemplate.t("colorTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    offerTemplate: dataTemplate.t("offerTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    priceContadoTemplate: dataTemplate.t("priceContadoTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    priceFinanciadoTemplate: dataTemplate.t("priceFinanciadoTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    priceTachadoTemplate: dataTemplate.t("priceTachadoTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    priceOfertaTemplate: dataTemplate.t("priceOfertaTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    phoneTemplate: dataTemplate.t("phoneTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    mobileTemplate: dataTemplate.t("mobileTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    emailTemplate: dataTemplate.t("emailTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    addressTemplate: dataTemplate.t("addressTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    concesionarioTemplate: dataTemplate.t("concesionarioTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    cambioTemplate: dataTemplate.t("cambioTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    cilindradaTemplate: dataTemplate.t("cilindradaTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    potenciaTemplate: dataTemplate.t("potenciaTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    motorTemplate: dataTemplate.t("motorTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    ivaDeducibleTemplate: dataTemplate.t("ivaDeducibleTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    consumoTemplate: dataTemplate.t("consumoTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    financeTemplate: dataTemplate.t("financeTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    financiaPrecioTemplate: dataTemplate.t("financiaPrecioTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    financiaAniosTemplate: dataTemplate.t("financiaAniosTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    matriculaTemplate: dataTemplate.t("matriculaTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    bastidorTemplate: dataTemplate.t("bastidorTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    fechaMatriculacionTemplate: dataTemplate.t("fechaMatriculacionTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    procedenciaTemplate: dataTemplate.t("procedenciaTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    titularidadTemplate: dataTemplate.t("titularidadTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    importadoTemplate: dataTemplate.t("importadoTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    comentarioTemplate: dataTemplate.t("comentarioTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    garantiaTemplate: dataTemplate.t("garantiaTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    horarioTemplate: dataTemplate.t("horarioTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    officialWarrantyTemplate: dataTemplate.t("officialWarrantyTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    basicWarrantyTemplate: dataTemplate.t("basicWarrantyTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    yearWarrantyTemplate: dataTemplate.t("yearWarrantyTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    premiumWarrantyTemplate: dataTemplate.t("premiumWarrantyTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    textTemplate: dataTemplate.t("textTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    financeItTemplate: dataTemplate.t("financeItTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    euroMonthTemplate: dataTemplate.t("euroMonthTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    duringTemplate: dataTemplate.t("duringTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                    yearsFinancingTemplate: dataTemplate.t("yearsFinancingTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                }
                if (dataTemplate.type === "VehicleValidation") {
                    const priceFinance = dataTemplate.vehiculo?.precio_oferta_particulares
                        ? dataTemplate.vehiculo?.precio_oferta_particulares
                        : dataTemplate.vehiculo?.pvp_financiado
                            ? dataTemplate.vehiculo?.pvp_financiado
                            : dataTemplate.vehiculo?.pvp_contado;
                    const cashPrice = dataTemplate.vehiculo?.pvp_contado;
                    const carMonth = moment(dataTemplate.vehiculo?.fecha_matriculacion).format("M");
                    const finance = await getFinance(
                        priceFinance,
                        dataTemplate.vehiculo?.matriculacion[0]?.label,
                        cashPrice,
                        carMonth
                    );
                    const translateData = {
                        combustible: dataTemplate.vehiculo?.combustible[0]?.label,
                        color: dataTemplate.vehiculo?.color[0]?.label,
                        cambio: dataTemplate.vehiculo?.ccambios[0]?.label,
                        procedencia: dataTemplate.vehiculo?.procedencia[0]?.label
                    };
                    const language = dataTemplate.optionLanguage.value;
                    let dataTranslated = translateData;
                    if (language !== "es") {
                        dataTranslated = await GetTextTranslate(translateData, language);
                    }
                    return {
                        ...dataTranslated,
                        titularidad: 
                            dataTemplate.vehiculo?.gestion_venta
                                ? dataTemplate.t("ajenaTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` })
                                : dataTemplate.t("propioTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                        url_imagen: dataTemplate.imagesPreviewUrls[0]?.url,
                        year: dataTemplate.vehiculo?.matriculacion[0]?.label,
                        brand: dataTemplate.vehiculo?.marca_vehiculo[0]?.label,
                        model: dataTemplate.vehiculo?.modelo[0]?.label,
                        transmission: dataTemplate.vehiculo?.ccambios[0]?.label,
                        version: dataTemplate.vehiculo?.version[0]?.label,
                        km: dataTemplate.vehiculo?.km,
                        ecoSticker: dataTemplate.ecoImg,
                        puertas: dataTemplate.vehiculo?.puertas,
                        plazas: dataTemplate.vehiculo?.asientos,
                        offer: dataTemplate.vehiculo?.oferta_particulares,
                        precio_contado: dataTemplate.vehiculo?.pvp_contado,
                        precio_financiado: dataTemplate.vehiculo?.pvp_financiado,
                        precio_oferta: dataTemplate.vehiculo?.precio_oferta_particulares,
                        precio_tachado: dataTemplate.vehiculo?.vehiculo?.pvp_financiado
                            ? dataTemplate.vehiculo?.pvp_financiado
                            : dataTemplate.vehiculo?.pvp_contado,
                        telefono_fijo: dataTemplate.localInfo?.telefono,
                        telefono_movil: dataTemplate.localInfo?.movil,
                        email: dataTemplate.localInfo?.email,
                        direccion: dataTemplate.localInfo?.direccion,
                        concesionario: dataTemplate.localInfo?.nombre,
                        anio: dataTemplate.vehiculo?.matriculacion[0]?.label,
                        cilindrada: dataTemplate.vehiculo?.cilindrada,
                        potencia: dataTemplate.vehiculo?.potencia,
                        motor:
                            dataTemplate.vehiculo?.cilindrada && dataTemplate.vehiculo?.potencia
                                ? `${dataTemplate.vehiculo?.cilindrada} cc / ${dataTemplate.vehiculo?.potencia} C.V.`
                                : "-",
                        iva_deducible: dataTemplate.vehiculo?.iva_deducible ? dataTemplate.t("yes", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` })
                        : dataTemplate.t("no", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                        consumo: dataTemplate.vehiculo?.consumo_combi,
                        finance: finance?.canFinance,
                        financia_precio: finance?.quota ? finance?.quota : null,
                        financia_anios: finance?.months
                            ? Math.floor(finance?.months / 12)
                            : null,
                        matricula: dataTemplate.vehiculo?.matricula,
                        bastidor: dataTemplate.vehiculo?.bastidor,
                        fecha_matriculacion: moment(dataTemplate.vehiculo?.fecha_matriculacion).format(
                            "DD-MM-YYYY"
                        ),
                        importado: dataTemplate.vehiculo?.importado,
                        comentario: dataTemplate.vehiculo?.comentario_impresion,
                        garantia: dataTemplate.vehiculo?.garantia[0]?.value === 3 ? dataTemplate.t("yes", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` })
                        : dataTemplate.t("no", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                        horario:
                            " Lunes a Viernes: 10:00 a 14:00h y 16:30 a 20:30h  Sábado: 10:00 a 14:30h y 16:30 a 20:30h  Domingo: Cerrado",
                        qr: '',
                        ...translationTemplate
                    }
                } else {
                    const priceFinance = dataTemplate.vehicle?.pvp_financiado ? dataTemplate.vehicle?.pvp_financiado : dataTemplate.vehicle?.pvp_contado;
                    const cashPrice = dataTemplate.vehicle?.pvp_contado;
                    const carMonth = moment(dataTemplate.vehicle?.fecha_matriculacion).format("M");
                    const finance = await getFinance(priceFinance, dataTemplate.vehicle?.anio, cashPrice, carMonth);
                    const translateData = {
                        combustible: dataTemplate.vehicle?.combustible,
                        color: dataTemplate.vehicle?.color,
                        cambio: dataTemplate.vehicle?.caja_cambios,
                        procedencia: dataTemplate.vehicle?.procedencia
                    };
                    const language = dataTemplate.optionLanguage.value;
                    let dataTranslated = translateData;
                    if (language !== "es") {
                        dataTranslated = await GetTextTranslate(translateData, language);
                    }
                    return {
                        ...dataTranslated,
                        titularidad: 
                            dataTemplate.vehicle?.vehicle_is_owned
                                ? dataTemplate.t("propioTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` })
                                : dataTemplate.t("ajenaTemplate", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                        url_imagen: dataTemplate.photos[0]?.url,
                        year: dataTemplate.vehicle?.anio,
                        brand: dataTemplate.vehicle?.titulo_vehiculo,
                        model: "",
                        transmission: dataTemplate.vehicle?.caja_cambios,
                        version: dataTemplate.vehicle?.version,
                        km: dataTemplate.vehicle?.kilometros,
                        ecoSticker: null,
                        puertas: dataTemplate.vehicle?.puertas,
                        plazas: dataTemplate.vehicle?.plazas,
                        offer: +dataTemplate.vehicle?.pvp_anterior > 0,
                        precio_contado: +dataTemplate.vehicle?.pvp_contado,
                        precio_financiado:
                            +dataTemplate.vehicle?.pvp_anterior > 0 ? +dataTemplate.vehicle?.pvp_anterior : +dataTemplate.vehicle?.pvp_financiado,
                        precio_tachado:
                            +dataTemplate.vehicle?.pvp_anterior > 0 ? +dataTemplate.vehicle?.pvp_anterior : +dataTemplate.vehicle?.pvp_contado,
                        precio_oferta: +dataTemplate.vehicle?.pvp_anterior > 0 ? +dataTemplate.vehicle?.pvp : null,
                        telefono_fijo: dataTemplate.localInfo?.telefono,
                        telefono_movil: dataTemplate.localInfo?.movil,
                        email: dataTemplate.localInfo?.email,
                        direccion: dataTemplate.localInfo?.direccion,
                        concesionario: dataTemplate.localInfo?.nombre,
                        anio: dataTemplate.vehicle?.anio,
                        cilindrada: dataTemplate.vehicle?.cilindrada,
                        potencia: dataTemplate.vehicle?.potencia,
                        motor:
                            dataTemplate.vehicle?.cilindrada && dataTemplate.vehicle?.cilindrada
                                ? `${dataTemplate.vehicle?.cilindrada} l / ${dataTemplate.vehicle?.potencia} C.V.`
                                : "-",
                        iva_deducible: dataTemplate.vehicle?.aplica_iva ? dataTemplate.t("yes", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` })
                            : dataTemplate.t("no", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                        consumo: dataTemplate.vehicle?.consumo_mixto,
                        finance: finance?.canFinance,
                        financia_precio: finance?.quota ? finance?.quota : null,
                        financia_anios: finance?.months ? Math.floor(finance?.months / 12) : null,
                        matricula: dataTemplate.vehicle?.matricula,
                        bastidor: dataTemplate.vehicle?.bastidor,
                        fecha_matriculacion: moment(dataTemplate.vehicle?.fecha_matriculacion).format("DD-MM-YYYY"),
                        importado: "",
                        comentario: dataTemplate.printExtraComment,
                        garantia: dataTemplate.vehicle?.garantia > 0 ? dataTemplate.t("yes", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` })
                            : dataTemplate.t("no", { ns: `modalCommentPdf${dataTemplate.optionLanguage.value}` }),
                        horario:
                            " Lunes a Viernes: 10: 00 a 14: 00h y 16: 30 a 20: 30h  Sábado: 10: 00 a 14: 30h y 16: 30 a 20: 30h Domingo: Cerrado",
                        qr: dataTemplate.vehicle.URL ? document.getElementById("qr").toDataURL("image/png") : "",
                        ...translationTemplate
                    };
                }
            }
            case 3:
                return {
                    url_imagen: dataTemplate.commercialProposal?.car.image,
                    name: dataTemplate.commercialProposal?.client.name,
                    email: dataTemplate.commercialProposal?.client.email,
                    apellidos: dataTemplate.commercialProposal?.client.lastname,
                    provincia: dataTemplate.commercialProposal?.client.province,
                    telefono_fijo: dataTemplate.commercialProposal?.client.phone,
                    poblacion: dataTemplate.commercialProposal?.client.location,
                    precio_financiado: Number(dataTemplate.commercialProposal?.finantial.total_financed),
                    precio_oferta: Number(dataTemplate.commercialProposal?.car.offer_pvp),
                    precio_contado: Number(dataTemplate.commercialProposal?.car.cash_pvp),
                    brand: dataTemplate.commercialProposal?.car.make,
                    model: dataTemplate.commercialProposal?.car.model,
                    version: dataTemplate.commercialProposal?.car.version,
                    ecoSticker: "",
                    anio: dataTemplate.commercialProposal?.car.year,
                    combustible: dataTemplate.commercialProposal?.car.fuel,
                    puertas: dataTemplate.commercialProposal?.car.doors,
                    plazas: dataTemplate.commercialProposal?.car.seats,
                    cambio: dataTemplate.commercialProposal?.car.gear,
                    seats: dataTemplate.commercialProposal?.car.seats,
                    matricula: dataTemplate.commercialProposal?.car.license_plate,
                    procedencia: dataTemplate.commercialProposal?.car.origin,
                    km: dataTemplate.commercialProposal?.car.km,
                    motor: dataTemplate.commercialProposal?.car.power,
                    consumo: dataTemplate.commercialProposal?.car.consume,
                    bastidor: dataTemplate.commercialProposal?.car.chassis,
                    iva_deducible: dataTemplate.commercialProposal?.car.vat ? "Sí" : "No",
                    color: dataTemplate.commercialProposal?.car.color,
                    fecha_matriculacion: dataTemplate.commercialProposal?.car.matriculation_date,
                    pvp: dataTemplate.commercialProposal?.pricing.pvp,
                    gestoria: Number(dataTemplate.commercialProposal?.pricing.gestoria),
                    garantia: Number(dataTemplate.commercialProposal?.pricing.warranty_premium),
                    seguro: Number(dataTemplate.commercialProposal?.pricing.insurance_allrisk),
                    total_pvp: Number(dataTemplate.commercialProposal?.pricing.total_pvp),
                    valido: dataTemplate.expired_at ? dataTemplate.expired_at : dataTemplate.commercialProposal?.expire_at,
                    total_financiado: Number(dataTemplate.commercialProposal?.finantial.total_financed),
                    entrada: Number(dataTemplate.commercialProposal?.finantial.down_payment),
                    cuota: Number(dataTemplate.commercialProposal?.finantial.fee),
                    plazo: dataTemplate.commercialProposal?.finantial.term,
                    tin: Number(dataTemplate.commercialProposal?.finantial.tin),
                    observations: dataTemplate.commercialProposal?.observations.replace(/\r?\n/g, '<br />'),
                    name_comercial: dataTemplate.commercialProposal?.commercial.commercial_name,
                    telefono_movil_comercial: dataTemplate.commercialProposal?.commercial.commercial_phone,
                    email_comercial: dataTemplate.commercialProposal?.commercial.commercial_email,
                    provincia_comercial: dataTemplate.commercialProposal?.commercial.dealer_direction,
                    dealer_email: dataTemplate.localInfo.email,
                    telefono_fijo_comercial: dataTemplate.commercialProposal?.commercial.dealer_phone,
                    dealer: dataTemplate.commercialProposal?.commercial.dealer,
                    offer: true,
                    transport_pvp: dataTemplate.commercialProposal?.pricing.transport_pvp,
                };
            case 4:
                return {
                    documentType: dataTemplate.typeDocumentTranslate[dataTemplate.templateInfo.type],
                    createdAt: dataTemplate.spanishFullDate,
                    clientFullName: dataTemplate.templateInfo.client.fullName,
                    identification: dataTemplate.templateInfo.client.identification,
                    phone: dataTemplate.templateInfo.client.phone,
                    warranty: dataTemplate.templateInfo.warranty?.name ?? "No",
                    isOwnerVerified: dataTemplate.templateInfo.isOwnerVerified ? "Sí" : "No",
                    hasDrivingLicence: dataTemplate.templateInfo.hasDrivingLicence ? "Sí" : "No",
                    agentFullName: `${dataTemplate.templateInfo.agent.name} ${dataTemplate.templateInfo.agent.firstSurname} ${dataTemplate.templateInfo.agent.secondSurname}`,
                    mail: dataTemplate.templateInfo.client.email,
                    local: dataTemplate.templateInfo.dealership.name,
                    afterSaleEmail: dataTemplate.templateInfo.afterSaleEmail,
                    make: dataTemplate.templateInfo.vehicle.make.name,
                    model: dataTemplate.templateInfo.vehicle.model.name,
                    plate: dataTemplate.templateInfo.vehicle.plate,
                    kmOnDelivery: dataTemplate.templateInfo.vehicle.kmOnDelivery.toLocaleString(),
                    clientIssue: dataTemplate.templateInfo.clientIssue || ""
                };
            case 5:
                const dataEjs = await post("/api/ejs_data", { id_contrato });
                let template_data = dataEjs[0].datos;

                if (dataTemplate.factura_id && dataTemplate.factura_fecha) {
                    template_data["factura_numero"] = dataTemplate.factura_id;
                    template_data["factura_fecha"] =
                        moment(dataTemplate.factura_fecha).format("DD-MM-YYYY");
                } else {
                    template_data["fecha"] = dataEjs[0].fecha;
                    template_data["fecha_modificacion"] = dataEjs[0].fecha_modificacion;
                };
                return { data: template_data };
            case 0:
                const ejs_data = await post("/api/ejs_data", { id_contrato });

                let ejs_data_template = ejs_data[0].datos;
                ejs_data_template["fecha"] = ejs_data[0].fecha;
                ejs_data_template["fecha_modificacion"] = ejs_data[0].fecha_modificacion;
                ejs_data_template["gestoria"] = {
                    gestor: ejs_data[0].gestor,
                    dni: ejs_data[0].dni,
                    numero: ejs_data[0].numero,
                    provincia: ejs_data[0].provincia,
                    direccion: ejs_data[0].direccion,
                };
                return { data: ejs_data_template };
            default:
                break;
        };
    };

    try {
        return (document.getElementById(divId).innerHTML = (
            await ejs.render(html, await getData(), { async: true })
        ).toString());
    } catch (err) {
        console.error(err);
    }

};

export default ejsTemplateRender;