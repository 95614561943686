import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "@schema/utils"
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "@redux/store";
import { UserContextProvider } from "@contexts/UserContext";
import './i18n';
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { environment } from "@config/environment.config";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <GoogleOAuthProvider clientId={environment.GOOGLE_CLIENT_ID}>
        <UserContextProvider>
          <App />
        </UserContextProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
